import { createRouter, createWebHashHistory } from "vue-router";

const hostName = window.location.hostname === "localhost" ?
  window.location.hostname :
  window.location.hostname;
  console.log(hostName);
const homePath = () => {
  let comp;
  switch (hostName) {
    case "localhost":
      comp = import('@/components/pages/yiyuan/home/Home');
      break;
    case "yiyuan.zhengju.cloud":
      comp = import('@/components/pages/yiyuan/home/Home');
      break;
    default:
      comp = import('@/components/pages/home/Home');
      break;
  }
  return comp;
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: homePath,
    meta: {
      title: '防患于未然',
      icon: 'yiyuan'
    }
  },
  {
    path: '/yiyuan/:type',
    name: 'yiyuan',
    component: () => import('@/components/pages/yiyuan/Index'),
    meta: {
      title: '防患于未然',
      icon: 'yiyuan'
    }
  },
  {
    path: '/downloadApp',
    name: 'downLoadApp',
    component: () => {
      let result;
      switch (hostName) {
        case 'intpr.gongzheng.com.cn'://国信
          result = import("@/components/pages/guoxin/down-load-app/DownLoad");
          break;
        case 'cacs.gongzheng.gov.cn'://长安
          result = import("@/components/pages/changan/down-load-app/DownLoad");
          break;
        default://默认国信
          result = import("@/components/pages/guoxin/down-load-app/DownLoad");
          break;
      }
      return result;
    },
    meta: {
      title: () => {
        let result;
        switch (hostName) {
          case 'intpr.gongzheng.com.cn'://国信
            result = '国信';
            break;
          case 'cacs.gongzheng.gov.cn'://长安
            result = '长安';
            break;
          default://默认国信
            result = '国信';
            break;
        }
        return result;
      },
      icon: () => {
        let result;
        switch (hostName) {
          case 'intpr.gongzheng.com.cn'://国信
            result = 'guoxin';
            break;
          case 'cacs.gongzheng.gov.cn'://长安
            result = 'changan';
            break;
          default://默认国信
            result = 'guoxin';
            break;
        }
        return result;
      }
    }
  }
]


export const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.afterEach(to => {
  if (to.meta) {
    var link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = `/${to.meta.icon}.ico`;
    document.getElementsByTagName("head")[0].appendChild(link);
    document.title = to.meta.title;
  }
})