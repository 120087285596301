<template>
  <router-view></router-view>
</template>

<script>
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 0.14rem;
  color: #333;
  li {
    list-style: none;
  }
  a {
    text-decoration: none;
  }
}
</style>
